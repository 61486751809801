<template>
    <!-- Top bar -->
    <slot name="top-bar">
        <template v-if="trialEndDiff">
            <div
                v-if="expiredSubscription && subscriptionDiscount"
                class="fixed top-0 z-50 flex w-full items-center justify-center gap-2.5 border-b border-gray-200 bg-primary-600 py-2.5"
            >
                <span
                    class="text-sm font-normal text-white"
                    v-html="
                        $filters.sanitize(
                            $t('heading.yourTrialExpired', {
                                discount: subscriptionDiscount,
                                d: trialEndDiff.day,
                                h: trialEndDiff.hour,
                                m: trialEndDiff.minute,
                                s: trialEndDiff.second,
                            })
                        )
                    "
                />
            </div>
            <div
                v-else
                class="fixed top-0 z-50 flex w-full items-center justify-center gap-2.5 border-b border-gray-200 bg-primary-50 py-2.5"
            >
                <base-icon name="line-icons:time:clock" variant="primary" />
                <span
                    class="text-sm font-normal text-gray-900"
                    v-html="
                        $filters.sanitize(
                            $t('heading.yourTrialExpired', {
                                d: trialEndDiff.day,
                                h: trialEndDiff.hour,
                                m: trialEndDiff.minute,
                                s: trialEndDiff.second,
                            })
                        )
                    "
                />
                <router-link
                    :to="{ name: 'subscriptions.change' }"
                    class="text-sm font-semibold text-primary-600"
                >
                    {{ $t('heading.buyAPlan') }}
                </router-link>
            </div>
        </template>
    </slot>

    <div
        class="relative flex h-full w-full flex-col"
        :class="{ 'pt-10': trialEndDiff }"
    >
        <!-- Top band navigation -->
        <partial-top-band
            @go-accountant="goAccountant"
            @switch-client="switchCompany"
        />

        <!-- Main -->
        <div class="flex h-[calc(100%-83px)] grow">
            <slot name="sidebar">
                <partial-sidebar
                    ref="sidebar"
                    class="h-full border-r border-gray-200 bg-white"
                    :menus="tenantNavigations"
                />
            </slot>
            <main
                class="flex min-w-0 flex-1 flex-col"
                :class="{
                    'overflow-auto': !state.stickyStyle,
                }"
            >
                <clients-switching
                    v-if="state.switchingName"
                    :name="state.switchingName"
                />
                <slot v-else-if="state.mounted" />
            </main>
        </div>

        <template v-if="state.loading">
            <div
                class="absolute bottom-0 right-0 z-50 bg-white bg-opacity-60"
                :class="[
                    trialEndDiff ? 'top-32' : 'top-[24px]',
                    isCollapsed ? 'left-20' : 'left-80',
                ]"
            >
                <span
                    class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform"
                >
                    <base-loading size="lg" />
                </span>
            </div>
        </template>
    </div>

    <partial-notification @on-answer="onAnswer" />

    <!-- Chat -->
    <partial-chat :group-id="tenantId" />

    <!-- Permission request -->
    <document-extraction-train-model-permission-modal
        v-if="permissionRequest && !hasPermissionConfig"
        :show="showUseDocumentForTrainingModal"
        :config="AITrainingConfig"
        @modal-close="showUseDocumentForTrainingModal = false"
        @modal-confirm="onPermissionConfirmed"
    />
</template>

<script setup>
import { tenantNavigations } from '@tenant/core/navigation'
import {
    ECHO_CHANNELS,
    ECHO_EVENTS,
    TIME_FORMAT,
} from '@tenant/utils/constants'
import { dayDiff, getDate, humanizedDuration, now } from '@tenant/utils/day'
import { DOCUMENT_PROCESSING_STATUS } from '@tenant/modules/tenant/document-extraction/utils/constants'
import { useIntercom } from '@mathieustan/vue-intercom'
import { useTenantListen } from '@tenant/composables'
import { setCurrencyFormat } from '@tenant/utils/helper'

const { t } = useI18n()
const router = useRouter()
const emitter = useEmitter()
const { successNotify, errorNotify } = useNotification()

const { centralUser, company, clientSwitch, tenantId } = useAuth()
const { defaultCurrency } = useCurrencies()

const intercom = useIntercom()

const sidebar = ref(null)

const state = reactive({
    switchingName: null,
    loading: false,
    stickyStyle: false,
    mounted: false,
})

let trialCountdown = null

watch(
    () => centralUser.value.email,
    (email) => {
        intercom.update({ email })
    }
)

watchEffect(() => {
    intercom.update()
})

onBeforeRouteUpdate((to, from, next) => {
    intercom.update()

    next()
})

onBeforeMount(() => {
    bootIntercom()
})

onMounted(() => {
    emitter.on('set-loading', setLoading)
    emitter.on('set-sticky-style', setStickyStyle)
    state.mounted = true

    trialCountdown = setInterval(() => {
        if (!trialEndDiff.value) {
            clearTrialCountdown()
            return
        }

        trialEndDiff.value.second--
    }, 1000)
})

onBeforeUnmount(() => {
    emitter.off('set-loading', setLoading)
    emitter.off('set-sticky-style', setStickyStyle)
    state.mounted = false
    clearTrialCountdown()
})

const isCollapsed = computed(() => sidebar.value?.isCollapsed)
const expiredSubscription = computed(() => company.value?.expired_subscription)
const trialEndDiff = computed(() => company.value?.trial_end_diff)
const subscriptionDiscount = computed(
    () => company.value?.subscription_discount
)

const clearTrialCountdown = () => {
    if (trialCountdown) {
        clearInterval(trialCountdown)
    }
}

const setBaseCurrency = () => {
    const baseCurrency = computed(() => {
        return company.value?.currency ?? defaultCurrency.value
    })

    setCurrencyFormat(baseCurrency.value)
}

watch(
    () => company.value?.currency,
    () => {
        setBaseCurrency()
    },
    { deep: true }
)

watch(
    () => defaultCurrency.value,
    () => {
        setBaseCurrency()
    },
    { deep: true }
)

watch(
    () => trialEndDiff.value,
    () => {
        if (!trialEndDiff.value) {
            return
        }

        const { second, minute, hour, day } = trialEndDiff.value
        if (second <= 0 && minute <= 0 && hour <= 0 && day <= 0) {
            clearTrialCountdown()
            return
        }

        if (second >= 0) {
            return
        }

        trialEndDiff.value.second = 59
        if (minute > 0) {
            trialEndDiff.value.minute--
            return
        }

        trialEndDiff.value.minute = 59
        if (hour > 0) {
            trialEndDiff.value.hour--
            return
        }

        trialEndDiff.value.hour = 23
        if (day > 0) {
            trialEndDiff.value.day--
        }
    },
    { deep: true }
)

const setLoading = (value) => {
    state.loading = value
}

const setStickyStyle = (stickyStyle) => {
    state.stickyStyle = stickyStyle
}

useTenantListen(
    ECHO_CHANNELS.GLOBAL,
    ECHO_EVENTS.PAYMENT_TRANSACTIONS_IMPORTED,
    ({ paymentAccountId, paymentAccountName, totalTransactions }) => {
        successNotify({
            text: t('messages.transactionsImported', {
                count: totalTransactions,
                name: paymentAccountName,
            }),
            viewChange: () => {
                router.push({
                    name: 'bookeeping.bank.detail',
                    params: {
                        id: `${paymentAccountId}`,
                    },
                })
            },
        })
    }
)

useTenantListen(
    ECHO_CHANNELS.GLOBAL,
    ECHO_EVENTS.CUSTOMER_SUBSCRIPTION_CREATED,
    () => {
        successNotify({
            title: t('subscriptions.messages.subscriptionIsActive'),
            text: t('subscriptions.messages.subscriptionIsNowAction'),
        })
    }
)

useTenantListen(
    ECHO_CHANNELS.GLOBAL,
    ECHO_EVENTS.DOCUMENT_EXTRACTION_UPLOADED,
    ({ processingId, status }) => {
        if (
            [
                DOCUMENT_PROCESSING_STATUS.FAILED,
                DOCUMENT_PROCESSING_STATUS.FAILURE,
            ].includes(status)
        ) {
            errorNotify({
                text: t('documentExtraction.uploadDocuments.documentsFailed', {
                    id: processingId,
                }),
            })
        }
    }
)

useTenantListen(
    ECHO_CHANNELS.GLOBAL,
    ECHO_EVENTS.CALENDAR_PUSH,
    ({ event }) => {
        const diff = dayDiff(event.start, now(), 'd')
        const eventDateText =
            diff === 0 ? t('general.today') : humanizedDuration(diff, 'd')

        successNotify({
            title: event.title,
            text: `${eventDateText} at ${getDate(event.start).format(
                TIME_FORMAT
            )}`,
        })
    }
)

const permissionRequest = ref(null)
useTenantListen(
    `permission.${centralUser.value.id}`,
    ECHO_EVENTS.DOC_EXTRACTION_PERMISSION,
    ({ fileName, folder, s3Key }) => {
        if (fileName && folder && s3Key) {
            permissionRequest.value = {
                file_name: fileName,
                folder,
                s3_key: s3Key,
            }
        }
    }
)

useListen(`chat.user.${centralUser.value.id}`, ECHO_EVENTS.CHAT_NEW)
useListen(`chat.user.${centralUser.value.id}`, ECHO_EVENTS.CHAT_MESSAGE)

const onAnswer = (notification) => {
    router.replace({
        query: {
            conversation: notification.chat_room_id,
        },
    })
}

const goAccountant = () => {
    if (state.switchingName) {
        return
    }

    state.switchingName = t('topBand.accountantDashboard')
    setTimeout(async () => {
        await router.push({
            name: 'accountant',
        })

        state.switchingName = null
    }, 2000)
}

const switchCompany = async ({ id, name }) => {
    if (state.switchingName) {
        return
    }

    state.switchingName = name
    try {
        await clientSwitch.execute(id)
        await router.go()
    } finally {
        state.switchingName = null
    }
}

onBeforeUnmount(() => {
    intercom.shutdown()
})

// USE DOCUMENT FOR TRAINING MODAL
const showUseDocumentForTrainingModal = ref(false)
const { execute: executeGetConfig, result: AITrainingConfig } = useApi(
    '/api/settings',
    'GET'
)
const { execute: executeSaveConfig } = useApi(
    '/api/document-extraction/confirm-document-permission/confirm',
    'POST'
)
onMounted(async () => {
    await executeGetConfig({
        queries: {
            keys: [
                'system.approvedToUseDocumentsForAITraining',
                'system.doNotShowPermissionToUseDocumentForTrainingModel',
            ],
        },
    })
    if (!AITrainingConfig.value.length) {
        showUseDocumentForTrainingModal.value = true
    } else {
        const configs = unref(AITrainingConfig)
        if (
            !configs?.[
                'system.doNotShowPermissionToUseDocumentForTrainingModel'
            ]
        ) {
            showUseDocumentForTrainingModal.value = true
        }
    }
})

const onPermissionConfirmed = async ({
    approvedToUseDocumentsForAITraining,
    doNotShowPermissionToUseDocumentForTrainingModel,
}) => {
    try {
        showUseDocumentForTrainingModal.value = false

        await executeSaveConfig({
            data: {
                payload: [
                    {
                        key: 'system.approvedToUseDocumentsForAITraining',
                        value: {
                            value: approvedToUseDocumentsForAITraining,
                        },
                    },
                    {
                        key: 'system.doNotShowPermissionToUseDocumentForTrainingModel',
                        value: {
                            value: doNotShowPermissionToUseDocumentForTrainingModel,
                        },
                    },
                ],
                ...permissionRequest.value,
            },
        })
    } catch ({ errorMessage }) {
        errorNotify({ text: errorMessage })
    }
}

const hasPermissionConfig = computed(() => {
    return (
        !!AITrainingConfig.value?.['system.approvedToUseDocumentsForAITraining']
            ?.value ||
        !!AITrainingConfig.value?.[
            'system.doNotShowPermissionToUseDocumentForTrainingModel'
        ]?.value
    )
})

const bootIntercom = () => {
    const { email, id: user_id, full_name, user_hash } = centralUser.value

    if (user_id && full_name && email) {
        intercom.boot({
            name: full_name,
            email: email,
            custom_launcher_selector: '#launch_messenger',
            trial_ends_at: company.value?.trial_ends,
            subscription_status: company.value?.subscription_status,
            user_id,
            user_hash,
        })
    }
}
// END: USE DOCUMENT FOR TRAINING MODAL
</script>
